/**
 * List of features that you can turn on | off in the application.
 *
 * The user will be allowed the feature if it is included in any applicable
 * plan or override.
 */
export enum BooleanFeature {
  /** Being able to access items which have been shared with you.
   * For now covers items shared via the AdvancedShareControls, but will also
   * include shared templates */
  AccessSharedItems = "AccessSharedItems",
  AdvancedShareControls = "AdvancedShareControls",
  /** demographics, filtering, segmentation, cross-tab */
  Analytics = "Analytics",
  /** Includes Webhooks */
  Api = "Api",
  /*Slack authenticated Web Links - supposed to be an Enterprise only feature*/
  AuthenticatedWebLinks = "AuthenticatedWebLinks",
  DisableExportsOnMobile = "DisableExportsOnMobile",
  DisableGuestWebLinks = "DisableGuestWebLinks",
  EnterpriseActivityLogs = "EnterpriseActivityLogs",
  EnterpriseAdminDashboard = "EnterpriseAdminDashboard",
  /** Customizations for the authoring experience -- currently only available for enterprise customers */
  EnterpriseCustomAuthoring = "EnterpriseCustomAuthoring",
  EnterpriseReporting = "EnterpriseReporting",
  EnterpriseSettingsDataRetention = "EnterpriseSettingsDataRetention",
  EnterpriseUserManagement = "EnterpriseUserManagement",
  EnterpriseLegacyBranding = "EnterpriseLegacyBranding",
  ExportQuestion = "ExportQuestion",
  EnterpriseIPWhitelistWebExports = "EnterpriseIPWhitelistWebExports",
  EnterpriseUsagePage = "EnterpriseUsagePage",
  EnterpriseUsagePageHideAvgMAU = "EnterpriseUsagePageHideAvgMAU",
  EnterpriseUsagePageHidePeakMAU = "EnterpriseUsagePageHidePeakMAU",
  /** Allows connecting a google sheet to a Polly object and syncronizing data
   * between them */
  GoogleSheetsSync = "GoogleSheetsSync",
  /** User is on a centrally managed trial */
  ManagedTrialLicense = "ManagedTrialLicense",
  /** Just a marker for non-free subscriptions */
  PaidTier = "PaidTier",
  /** User can create official pollys */
  OfficialMessage = "OfficialMessage",
  /** User sees Request License button when feature not permitted by free */
  RequestManagedTrialLicense = "RequestManagedTrialLicense",
  SlashPollyExport = "SlashPollyExport",
  SlashPollyShare = "SlashPollyShare",
  ManageSuggestionBox = "ManageSuggestionBox",
  TrendHistory = "TrendHistory",
  Workflows = "Workflows",
  QnAv2 = "QnAv2",
  DisableLLM = "DisableLLM",

  /** Enterprise crackdown flags */
  DisableAnonymity = "DisableAnonymity",
  DisablePrivateResults = "DisablePrivateResults",
  DisableSchedulingOptions = "DisableSchedulingOptions",
  OrgMonthlyResponseLimitEnabled = "OrgMonthlyResponseLimitEnabled",
  DisableResponseLimitCreationBlocking = "DisableResponseLimitCreationBlocking",
  EnableResultsFiltering = "EnableResultsFiltering",
}
/**
 * List of features that have numeric max values associated with them.
 *
 * The user will receive the max of the default value and values from any
 * applicable plans and overrides.
 */
export enum NumericFeature {
  DaysOfHistory = "DaysOfHistory",
  DirectMessageAudienceLimit = "DirectMessageAudienceLimit",
  /** Responses Per Month Used for Response Based Paywall */
  ResponsesPerMonth = "ResponsesPerMonth",
  /** Enterprise Crackdown */
  OrgMonthlyResponseLimit = "OrgMonthlyResponseLimit",
}

/**
 * For each NumericFeature a default value must be specified.
 * This is the quantity included with no subscribed plan.
 */
export const NumericFeatureDefaultValues: {
  [key in NumericFeature]: number;
} = {
  [NumericFeature.DirectMessageAudienceLimit]: 500,
  [NumericFeature.OrgMonthlyResponseLimit]: 0,
  [NumericFeature.DaysOfHistory]: 45,
  [NumericFeature.ResponsesPerMonth]: 25,
};

/**
 * For each feature an optional name may be specified for use in UI.
 *
 * If no name is specified here, the enum string will be used.
 */
export const UserFriendlyFeatureNames: {
  [key in BooleanFeature]?: string;
} & {
  [key in NumericFeature]?: string;
} = {
  [BooleanFeature.AccessSharedItems]: "Shared Items",
  [BooleanFeature.AdvancedShareControls]: "Advanced Share Controls",
  [BooleanFeature.Api]: "APIs",
  [BooleanFeature.AuthenticatedWebLinks]: "Authenticated Web Voting",
  [BooleanFeature.EnterpriseActivityLogs]: "Activity Logs",
  [BooleanFeature.EnterpriseAdminDashboard]: "Enterprise Admin Dashboards",
  [BooleanFeature.EnterpriseCustomAuthoring]: "Enterprise Custom Authoring",
  [BooleanFeature.EnterpriseReporting]: "Enterprise Reports",
  [BooleanFeature.EnterpriseSettingsDataRetention]: "Enterprise Settings",
  [BooleanFeature.EnterpriseUserManagement]: "User Management Tools",
  [BooleanFeature.ExportQuestion]: "Exports",
  [BooleanFeature.ManagedTrialLicense]: "Managed Trial License",
  [BooleanFeature.PaidTier]: "Paid Tier",
  [BooleanFeature.RequestManagedTrialLicense]: "Request Managed Trial License",
  [BooleanFeature.SlashPollyExport]: "Exports",
  [BooleanFeature.SlashPollyShare]: "Shares",
  [BooleanFeature.TrendHistory]: "Trend History",
};
