const STATIC_POLLY_URL =
  process.env.STATIC_POLLY_URL || "https://static.polly.ai";

function url(path: string): string {
  return `${STATIC_POLLY_URL}${path}`;
}

export default class StaticAssets {
  static readonly APPLE_TOUCH_ICON = url("/apple-touch-icon.png");
  static readonly BUSINESS_BG = url("/img/business-bg.png");
  static readonly BUY_NOW_IMAGE = url("/img/buy-now-image.svg");
  static readonly EMPLOYEE_ONBOARDING_DETAIL = url(
    "/img/employee-onboarding-detail.png",
  );
  static readonly FAVICON = url("/favicon.ico");
  static readonly FEEDBACK_LOGO = url("/img/feedback-logo.png");
  static readonly FREE_BG = url("/img/free-bg.png");
  static readonly FREE_TRIAL = url("/img/free-trial.png");
  static readonly HELP_DESK_MONITOR = url("/img/help-desk-monitor.png");
  static readonly LOADER = url("/img/Loader.gif");
  static readonly LOADER_SPARKLES = url("/img/Loader-Sparkles.gif");
  static readonly LOGO_BLUE_BACKGROUND = url("/img/logo-blue-background.png");
  static readonly LOGO_BLUE_BACKGROUND_100PX = url(
    "/img/logo_blue_background_100px.png",
  );
  static readonly LOGO_COLOR = url("/img/logo-color.svg");
  static readonly LOGO_COLOR_WHITE_TEXT_RIGHT = url(
    "/img/logo-color-white-text-right.svg",
  );
  static readonly LOGO_COLOR_BLACK_TEXT_RIGHT = url(
    "/img/logo-color-black-text-right.svg",
  );
  static readonly LOGO_COLOR_44 = url("/img/slack-logo-color-44.png");
  static readonly LOGO_MONO = url("/img/logo-mono.svg");
  static readonly MISSING_ITEM = url("/img/missing-item.png");
  static readonly NO_RESULTS = url("/img/no-results.svg");
  static readonly PLAY = url("/img/play-image.png");
  static readonly POLLY_API_PLACEHOLDER = url("/img/polly-api-placeholder.png");
  static readonly POLLY_BIRD_WINK = url("/img/polly_bird_wink.png");
  static readonly POLLY_BROKEN_HEART_SLACK = url(
    "/img/polly-broken-heart-slack.png",
  );
  static readonly POLLY_EGG_ANIMATION = url("/img/polly_egg_animation.svg");
  static readonly POLLY_HEART_SLACK = url("/img/polly-heart-slack.png");
  static readonly PROFILE_IMAGE_POLLY = url("/img/profile-image-polly.png");
  static readonly STANDUP_ANIMATION = url("/img/standup_animation2.gif");
  static readonly NUDGE_TEMPLATES_ANIMATION = url("/img/nudge/Templates.gif");
  static readonly NUDGE_MEETING_TEMPLATES_ANIMATION = url(
    "/img/nudge/Meeting-templates.gif",
  );
  static readonly NUDGE_FINAL_ANIMATION = url("/img/nudge/Final_animation.gif");
  static readonly NUDGE_FINAL_STATIC_IMG = url(
    "/img/nudge/Final_static_image.png",
  );
  static readonly SLACK_STANDUPS = url("/img/SlackStandups.gif");
  static readonly SLACK_LOGO_GREY_SCALE = url(
    "/img/slack-logo-greyscale-01.svg",
  );
  static readonly STANDUPS_BUTTON = url("/img/standups-button.gif");
  static readonly START_TRIAL_ANIMATION = url("/img/SU-animation-Slack.gif");
  static readonly STARTER_BG = url("/img/starter-bg.png");
  static readonly SURVEY = url("/img/survey.svg");
  static readonly TICKET_SUPPORT_FEEDBACK = url(
    "/img/ticket_support_feedback.png",
  );
  static readonly TRIAL_NOTIFICATION_ICON = url(
    "/img/icons/trial_notification_icon_image.svg",
  );
  static readonly TRIAL_START_IMAGE = url("/img/trial-start-image.svg");
  static readonly WELCOME_ANIMATION = url("/img/Welcome-animation.gif");
  static readonly WELCOME_STATIC = url("/img/Welcome-static-2.png");
  static readonly WINKING_EMOJI = url("/img/winking-emoji.svg");
  static readonly WORK = url("/img/work-image.png");
  static readonly AUTHORING_MODAL_SUCCESS = url("/img/vote-animate-1.svg"); // web authoring
  static readonly AUTHORING_MODAL_SUCCESS_GIF = url(
    "/img/vote-confirmation-animation-sized.gif",
  ); // slack authoring - animated svgs dont work in slack
  static readonly EMPTY = url("/img/empty.svg");
  static readonly GOOGLE_LOGO = url("/img/google-logo.svg");
  static readonly BLUE_CHECK = url("/img/blue-check.png");
  static readonly OVERFLOW_MENU = url("/img/overflow-menu.png");
  static readonly VISIT_DASHBOARD = url("/img/visit-dasboard.png");
  static readonly QNA_COLLABORATOR_EMPTY = url(
    "/img/qna-collaborator-empty.svg",
  );
  static readonly FILTER_EMPTY = url("/img/empty-filter-icon.svg");

  static readonly billing = {
    AGILE_BG: url("/img/billing/agile_bg.png"),
    SOCIAL_BG: url("/img/billing/social_bg.png"),
    LEADER_BG: url("/img/billing/leader_bg.png"),
    PRO_BG: url("/img/billing/pro_bg.png"),
    ENTERPRISE_BG: url("/img/billing/enterprise_bg.png"),
    POWERED_BY_STRIPE: url("/img/billing/powered_by_stripe.png"),
    CHECKOUT_CONFIRMATION: url(
      "/img/checkout-confirmation/checkout-confirmation.png",
    ),
  };

  static readonly cards = {
    VISA: url("/img/cards/Visa.png"),
    DISCOVER: url("/img/cards/Discover.png"),
    AMERICAN_EXPRESS: url("/img/cards/AmericanExpress.png"),
    MASTER_CARD: url("/img/cards/MasterCard.png"),
    JCB: url("/img/cards/JCB.png"),
    DINERS_CLUB: url("/img/cards/DinersClub.png"),
  };

  static readonly help = {
    EXAMPLE_IMPORT: url("/help/polly-example-import.csv"),
    EXAMPLE_WEIGHTED_VOTING_CSV: url("/help/polly-weighted-voting-example.csv"),
    POLL_PREVIEW_ANIMATED: url("/help/poll-preview-animated.gif"),
    POLL_PREVIEW_OPTIONS: url("/help/poll-preview-options.png"),
    POLL_PREVIEW_RECURRING: url("/help/poll-preview-recurring.png"),
    SLASHPOLL: url("/help/slashpoll.gif"),
    SLASHPOLL_MC: url("/help/slashpoll-mc.gif"),
  };

  static readonly jsd = {
    WORKFLOW_IN_ACTION_SHOTS: url(
      "/img/jira-service-desk/workflow-in-action-shots.png",
    ),
    WORKFLOW_RESULTS_SHOTS: url(
      "/img/jira-service-desk/workflow-results-shots.png",
    ),
  };

  static readonly remoteToolkit = {
    icons: {
      STANDUPS: url("/img/remote-toolkit/icons/standups-image.png"),
      STANDUPS_SELECTED: url(
        "/img/remote-toolkit/icons/standups-image-selected.png",
      ),
      EMPLOYEE_AWARDS: url(
        "/img/remote-toolkit/icons/employee-awards-image.png",
      ),
      EMPLOYEE_AWARDS_SELECTED: url(
        "/img/remote-toolkit/icons/employee-awards-image-selected.png",
      ),
      HOT_TAKES: url("/img/remote-toolkit/icons/hot-takes-image.png"),
      HOT_TAKES_SELECTED: url(
        "/img/remote-toolkit/icons/hot-takes-image-selected.png",
      ),
      TRIVIA: url("/img/remote-toolkit/icons/trivia-image.png"),
      TRIVIA_SELECTED: url(
        "/img/remote-toolkit/icons/trivia-image-selected.png",
      ),
      WFH_PULSE: url("/img/remote-toolkit/icons/wfh-pulse-survey-image.png"),
      WFH_PULSE_SELECTED: url(
        "/img/remote-toolkit/icons/wfh-pulse-survey-image-selected.png",
      ),
    },
    previews: {
      STANDUPS: url("/img/remote-toolkit/previews/employee-check-in-image.png"),
      HOT_TAKES: url(
        "/img/remote-toolkit/previews/hot-takes-dropdown-animation.gif",
      ),
      TRIVIA: url("/img/remote-toolkit/previews/trivia-dropdown-animation.gif"),
      EMPLOYEE_AWARDS: url(
        "/img/remote-toolkit/previews/employee-awards-animation.gif",
      ),
      WFH_PULSE: url(
        "/img/remote-toolkit/previews/wfh-pulse-dropdown-image.png",
      ),
    },
    timers: {
      SECONDS_15: url("/img/remote-toolkit/timer-gifs/timer_seconds_15_1.gif"),
      SECONDS_30: url("/img/remote-toolkit/timer-gifs/timer_seconds_30_1.gif"),
      SECONDS_60: url("/img/remote-toolkit/timer-gifs/timer_seconds_60_1.gif"),
      SECONDS_90: url("/img/remote-toolkit/timer-gifs/timer_seconds_90_1.gif"),
    },
    LEFT_NAV_BUTTON: url("/img/remote-toolkit/leftnav-button.gif"),
  };

  static readonly slack = {
    DEFAULT_SLACK_AVATAR_48: url("/img/slack/slack-avatar-48.png"),
    ADD_POLLY_TO_SLACK: url("/img/install-polly.png"),
    SITE_WIDE_PLAN_PURCHASED_DM: url(
      "/img/onboarding/SiteWidePlanPurchasedDM.png",
    ),

    commands: {
      REMIND: url("/img/slack/commands/Remind_confirmation_image.png"),
    },

    confirmation: {
      CLOSE: url("/img/slack/confirmation-images/close.png"),
      DELETE: url("/img/slack/confirmation-images/delete.png"),
      EMPTY_STATE: url("/img/slack/confirmation-images/empty_state.png"),
      EXPORT: url("/img/slack/confirmation-images/export.png"),
      REMIND: url("/img/slack/confirmation-images/remind.png"),
      SHARE: url("/img/slack/confirmation-images/share.png"),
      SURVEY: url("/img/slack/confirmation-images/survey_completion.png"),
      PAUSED: url("/img/slack/confirmation-images/paused.png"),
    },
  };
  static readonly template = {
    COMMUNITY_MANAGER_TEMPLATE: url(
      "/img/templateassets/community-manager-template.png",
    ),
    COMMUNITY_NPS_TEMPLATE: url(
      "/img/templateassets/community-nps-template.png",
    ),
    CUSTOM_TEMPLAGE: url("/img/templateassets/custom-template.png"),
    ENGINEERING_TEMPLATE: url("/img/templateassets/engineering-template.png"),
    FIRST_THIRTY_DAYS_TEMPLATE: url(
      "/img/templateassets/first-30-days-on-the-job.png",
    ),
    FIRST_NINETY_DAYS_TEMPLATE: url(
      "/img/templateassets/first-ninety-days-template.png",
    ),
    GENERAL_TEMPLATE: url("/img/templateassets/general-template.png"),
    HAPPINESS_PULSE_SURVEY_TEMPLATE: url(
      "/img/templateassets/happiness-pulse-survey-template.png",
    ),
    HR_TEMPLATE: url("/img/templateassets/hr-template.png"),
    JSD_TEMPLATE: url("/img/templateassets/jsd-template.png"),
    MONTHLY_HAPPINESS_CHECKIN_TEMPLATE: url(
      "/img/templateassets/monthly-happiness-checkin-template.png",
    ),
    NEW_EMPLOYEE_TEMPLATE: url("/img/templateassets/new-employee-template.png"),
    SPRINT_RETROSPECTIVE_TEMPLATE: url(
      "/img/templateassets/sprint-retrospective-template.png",
    ),

    ALL_HANDS_MEETING_FEEDBACK: url(
      "/img/templateassets/all-hands-meeting-feedback.png",
    ),
    ALL_HANDS_MEETING_PREP: url(
      "/img/templateassets/all-hands-meeting-prep.png",
    ),
    AMA: url("/img/templateassets/ama.png"),
    COMMUNITY_PRODUCT_FEATURE: url(
      "/img/templateassets/community-product-feature.png",
    ),
    DAILY_STANDUP: url("/img/templateassets/daily-standup.png"),
    HELP_DESK_MONITOR: url("/img/templateassets/help-desk-monitor.png"),
    INTERVIEW_CANDIDATE_FEEDBACK: url(
      "/img/templateassets/interview-candidate-feedback.png",
    ),
    MID_SPRINT_CHECKIN: url("/img/templateassets/mid-sprint-check-in.png"),
    NEW_EMPLOYEE_ONBOARDING: url(
      "/img/templateassets/new-employee-onboarding.png",
    ),
    PRODUCT_NPS: url("/img/templateassets/product-nps.png"),
    QUARTERLY_EMPLOYEE_ENGAGEMENT_SURVEY: url(
      "/img/templateassets/quarterly-employee-engagement-survey.png",
    ),
    SLACK_ADOPTION_NPS: url("/img/templateassets/slack-adoption-nps.png"),
    SLACK_DEPLOYMENT_FOLLOWUP: url(
      "/img/templateassets/slack-deployment-followup.png",
    ),
    SNACK_REPLENISHMENT_SCREENSHOT: url(
      "/img/templateassets/snack-replenishment-screenshot.png",
    ),
    SPRINT_PROSPECTIVE: url("/img/templateassets/sprint-prospective.png"),
    SUGGESTION_BOX: url("/img/templateassets/suggestion-box.png"),

    LEADER_TEMPLATE: url("/img/templateassets/leader-template.png"),
    AGILE_TEMPLATE: url("/img/templateassets/agile-template.png"),
    PRE_SPRINT_PLANNING: url("/img/templateassets/pre-sprint-planning.png"),
    WEEKLY_TEAM_CHECK_IN: url("/img/templateassets/weekly-team-check-in.png"),
    MEETING_PREP: url("/img/templateassets/meeting-prep.png"),
    MEETING_FEEDBACK: url("/img/templateassets/meeting-feedback.png"),
    MEETING_1_1_COORDINATION: url(
      "/img/templateassets/1-1-meeting-coordination.png",
    ),
    DIRECT_REPORT_FEEDBACK: url(
      "/img/templateassets/direct-report-check-in.png",
    ),
    MANAGER_FEEBDACK: url("/img/templateassets/manager-feedback.png"),
    HR_BENEFITS_SURVEY: url("/img/templateassets/hr-benefits-survey.png"),
    E_NPS: url("/img/templateassets/eNPS-template-image_test.png"),
    REMOTE_PULSE: url("/img/templateassets/remote_pulse.png"),
    REMOTE_INSIGHTS: url("/img/templateassets/remote_insights.png"),
    ROTATING_PULSE: url("/img/templateassets/rotating_pulse.png"),
    SELF_CERTIFICATION_PULSE: url(
      "/img/templateassets/self_certification_pulse.png",
    ),

    PREVIEW_HOT_TAKE: url("/img/templateassets/RT-Hot-takes.gif"),
    PREVIEW_TRIVIA: url("/img/templateassets/RT-Trivia.gif"),
    PREVIEW_EMPLOYEE_AWARDS: url("/img/templateassets/RT-employee-awards.gif"),
  };

  static readonly tomo = {
    INDUSTRY_COMPARISON: url("/img/tomo/tomo_industry_comparison.png"),
    ROLE_COMPARISON: url("/img/tomo/tomo_role_comparison.png"),
  };

  static readonly upgrade = {
    LEFT: url("/img/upgrade-request-assets/upgrade-request-left.png"),
    RIGHT: url("/img/upgrade-request-assets/upgrade-request-right.png"),
  };

  static readonly upgradePlan = {
    FREE: url("/img/upgrade-plan/plan-free.png"),
    STANDARD: url("/img/upgrade-plan/plan-standard-pro.png"),
  };

  static readonly webVoting = {
    LINK_GENERATION_CONFIRMATION: url("/img/web-voting/link-animation.svg"), // web authoring
    LINK_GENERATION_CONFIRMATION_GIF: url(
      "/img/web-voting/link-animation-sized.gif",
    ), // slack authoring since slack doesnt like animated svgs
  };

  static readonly workflow = {
    CUSTOM_WORKFLOW: url("/img/workflowassets/custom-workflow.png"),
    HELP_DESK_MONITOR: url("/img/workflowassets/help-desk-monitor.png"),
    NEW_EMPLOYEE_ONBOARDING: url(
      "/img/workflowassets/new-employee-onboarding.png",
    ),
    SLACK_ONBOARDING_MONITOR: url(
      "/img/workflowassets/slack-onboarding-monitor.png",
    ),

    marketingPreviews: {
      CARD_CUSTOM: url(
        "/img/workflowassets/marketing-previews/card-custom.png",
      ),
      CARD_EMPLOYEE_ONBOARDING: url(
        "/img/workflowassets/marketing-previews/card-employee-onboarding.png",
      ),
      CARD_HELPDESK_MONITOR: url(
        "/img/workflowassets/marketing-previews/card-helpdesk-monitor.png",
      ),
      CARD_JSD: url("/img/workflowassets/marketing-previews/card-jsd.png"),
      CARD_SLACK_ADOPTION_FEEDBACK: url(
        "/img/workflowassets/marketing-previews/card-slack-adoption-feedback.png",
      ),
      HERO_CUSTOM: url(
        "/img/workflowassets/marketing-previews/hero-custom.png",
      ),
      HERO_MEETING_EFFECTIVENESS: url(
        "/img/workflowassets/marketing-previews/hero-meeting-effectiveness.png",
      ),
      HERO_JSD: url("/img/workflowassets/marketing-previews/hero-jsd.png"),
      HERO_TRIAGE_CHANNEL: url(
        "/img/workflowassets/marketing-previews/hero-triage-channel.png",
      ),
    },

    preview: {
      SLACK_ADOPTION_FEEDBACK: url(
        "/img/workflowassets/preview/slack-adoption-feedback.svg",
      ),
    },
  };
  static readonly integrations = {
    GOOGLE_SHEETS_IMG: url("/img/integrations/google-sheets-img.png"),
  };
  static readonly pages = {
    WORKFLOWS: url("/img/pages/workflows.png"),
    API: url("/img/pages/api.png"),
    EXPORTS: url("/img/pages/exports.png"),
    DEMOGRAPHICS: url("/img/pages/demographics.png"),
    QNA_HERO: url("/img/pages/qna-hero.svg"),
    QNA_PREVIEW: url("/img/pages/qna-slack-preview.gif"),
  };

  static readonly tour = {
    DEMO: url("/img/tour/polly-intro-demo.png"),
    DEMOGRAPHICS: url("/img/tour/polly-intro-demographics.gif"),
    STANDUP: url("/img/tour/polly-intro-standup.gif"),
    SUGGESTION_BOX: url("/img/tour/polly-intro-suggestion-box.gif"),
    SURVEY: url("/img/tour/polly-intro-survey.gif"),
    TRIVIA: url("/img/tour/polly-intro-trivia.gif"),
    UPGRADE: url("/img/tour/polly-intro-upgrade.png"),
    WORKFLOWS: url("/img/tour/polly-intro-workflows.gif"),
    PRESENTATIONS: url("/img/tour/polly-presentation.png"),
    STANDUPS: url("/img/tour/polly-standups.gif"),
    QUESTIONANDANSWERS: url("/img/tour/polly-intro-questions-and-answer.png"),
  };

  static readonly enterpriseUpgradeModal = {
    BANNER_WAVES: url("/img/web/banner-waves.png"),
    POLLY_ENGAGEMENT: url("/img/web/enterprise-upgrade-engagement.png"),
    ICONS: {
      QNA: url("/img/web/icons/Q&A.svg"),
      POLLS_N_SURVEYS: url("/img/web/icons/polls&surveys.svg"),
      PULSE_CHECK: url("/img/web/icons/pulse-checks.svg"),
      SUGGESTION_BOX: url("/img/web/icons/suggestion-box-color.svg"),
      TEAM_BUILDING: url("/img/web/icons/team-building-color.svg"),
      WORKFLOWS: url("/img/web/icons/workflows-color.svg"),
      POLLY_ICON_5: url("/img/web/icons/polly-icon-5.svg"),
    },
  };
  static readonly QNA = {
    CARD: url("/img/qna/qna-card.svg"),
    UPVOTES: url("/img/qna/qna-anon-card.svg"),
    ANONYMOUS: url("/img/qna/qna-upvotes-card.svg"),
    DEMO_VIDEO_POSTER: url("/img/qna/qna-demo-poster.jpg"),
    DEMO_VIDEO:
      "https://5957403.fs1.hubspotusercontent-na1.net/hubfs/5957403/Q&A%20Next-Gen%20v2.mp4",
    WEB_ICON: url("/img/qna/web-icon.svg"),
    WEB_ONLY_PREVIEW: url("/img/qna/web-qna-preview.svg"),
  };

  static readonly ResultsBlocked = {
    DAYS_7: url("/img/results-blocked/results-blocked-7.png"),
    DAYS_14: url("/img/results-blocked/results-blocked-14.png"),
    DAYS_30: url("/img/results-blocked/results-blocked-30.png"),
    DAYS_45: url("/img/results-blocked/results-blocked-45.png"),
    RESULTS_FULL_BLOCKED: url("/img/results-blocked/results-full-blocked.png"),
  };
}
